import React from "react";

import styled from './MusicHeader.module.scss'
import { ReactComponent as Returnable } from '../../assets/musicbet/returnable.svg'
import { Button, Popover } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import ReactClipboard from "react-clipboardjs-copy";
import { toast } from "react-toastify";
import { NotificationT } from "../ToastifyNot/NotificationToastify";
import { ReactComponent as Share } from '../../assets/musicbet/Undo button.svg'
import useClipboard from "react-use-clipboard";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";

interface HeaderProps {
  title: string,
  primaryColor: string
  color?: string,
  fontSize?: number
  returnable?: boolean
  shareable?: boolean
}


const MusicHeader = (props: HeaderProps) => {
  const [isCopied, setCopied] = useClipboard(window.location.href);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const popoverContent = (
    <div className={styled.popover_content}>

      <FacebookShareButton
        url={window.location.href}
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={window.location.href}
      >
        <XIcon size={32} round />
      </TwitterShareButton>
      <TelegramShareButton
        url={window.location.href}
      >
        <TelegramIcon size={32} round />
      </TelegramShareButton>
      <WhatsappShareButton
        url={window.location.href}
      >
        <WhatsappIcon size={32} round />
      </WhatsappShareButton>
      <RedditShareButton
        url={window.location.href}
      >
        <RedditIcon size={32} round />
      </RedditShareButton>
      <ViberShareButton
        url={window.location.href}
      >
        <ViberIcon size={32} round />
      </ViberShareButton>
    </div>
  )



  const navigate = useNavigate()
  return (
    <>
      {/* <NotificationT/> */}
      <div style={{ background: `linear-gradient(90deg, #000,${props.primaryColor},#000` }} className={styled.music_header}>
        {!isDesktop && props.shareable ?
          <Popover style={{width: "50%"}} placement="bottom" content={popoverContent} trigger={'click'}>
            <Button type="link" className={styled.share}><Share /></Button>
          </Popover>
          :
          <></>}
        <p className={styled.title} style={{ color: props.color, fontSize: props.fontSize }}>{props.title}</p>
        {
          !isDesktop && props.returnable ? <Button onClick={() => navigate(-1)} type="link" className={styled.return}><Returnable /></Button> : <></>
        }

      </div>
    </>
  )
}

export default MusicHeader;
