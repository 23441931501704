import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

// Импорт редьюсеров
import mainMenuSlice from './mainMenuSlice';
import mobileMenuSlice from './mobileMenuSlice';
import playerSlice from './playerSlice';
import battleSlice from './battleSlice';
import loginMenuModal from './loginModalSlice';
import isAuthSlice from './isAuthSlice';
import musicCategoriesSlice from './musicCategoriesSlice';

// Конфигурация для persist с использованием sessionStorage
const persistConfig = {
  key: 'root',
  storage: storageSession,
};

// Создаем объединенный редьюсер
const rootReducer = combineReducers({
  mobileMenu: mobileMenuSlice,
  mainMenu: mainMenuSlice,
  player: playerSlice,
  modalLogin: loginMenuModal,
  battle: battleSlice,
  musicCategories: musicCategoriesSlice,
  isAuth: isAuthSlice,
});

// Оборачиваем rootReducer в persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создаем store с обернутым редьюсером
const store = configureStore({
  reducer: persistedReducer,
});

// Создаем persistor для управления сохранением состояния
const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
