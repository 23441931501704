import { useEffect, useState } from "react";
import { Avatar, Button, Col, Divider, Input, Row, Space, Tree } from "antd";
import {
	CloseOutlined,
	LoadingOutlined,
	SendOutlined,
	LikeOutlined,
	LikeFilled,
	DislikeOutlined,
	DislikeFilled,
	UserOutlined
} from '@ant-design/icons';
import Picker from 'emoji-picker-react'
import styles from './Comments.module.scss'

import { crowdfundingAPI, videoAPI } from "../../../api/api";

import { ReactComponent as Send } from '../../../assets/svg/send_FILL0_wght300_GRAD0_opsz24.svg';
import { ReactComponent as Mood } from '../../../assets/svg/mood_FILL0_wght300_GRAD0_opsz24.svg';
import { useAppSelector } from "../../../hooks/reduxHooks";

const Comments = ({ videoId, crowdfundingId }: any) => {

	const [comments, setComments] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [isLoadingAnswer, setIsLoadingAnswer] = useState(false)
	const [showPicker, setShowPicker] = useState(false);
	const [showPickerA, setShowPickerA] = useState(false);
	const [text, setText] = useState("")
	const [answer, setAnswer] = useState("")
	const [commentModeId, setCommentModeId] = useState(null)
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const { avatar } = useAppSelector((state) => state.player);


	const loadCommeents = () => {
		videoAPI
			.getComments(videoId)
			.then((res) => setComments(res))
		// .then((res) => console.log(res))
		// .then((res) => {
		// 	console.log(res)
		// })

	}

	const onSelect = (id) => {
		setAnswer("")
		if (commentModeId === id) setCommentModeId(null)
		setCommentModeId(id)
	};

	const handlePostComment = () => {
		setIsLoading(true)
		videoAPI.postComment(videoId, text)
			.then(res => {
				setText("")
				setIsLoading(false)
				loadCommeents()
			})
			.catch((error) => {
				setIsLoading(false)
				console.error('Post Error: ', error)
			})
	}


	// сделать такое для crowd
	const handlePostLike = (commentId: any) => {
		setIsLoading(true)
		videoAPI.postVote(commentId, "like")
			.then(res => {
				setIsLoading(false)
				loadCommeents()
			})
			.catch((error) => {
				setIsLoading(false)
				console.log('Post Error: ', error)
			})
	}

	// сделать такое для crowd
	const handlePostDislike = (commentId: any) => {
		setIsLoading(true)
		videoAPI.postVote(commentId, "dislike")
			.then(res => {
				setIsLoading(false)
				loadCommeents()
			})
			.catch((error) => {
				setIsLoading(false)
				console.log('Post Error: ', error)
			})
	}

	//сделать такое для crowd
	const handleAnswerComment = (parentId) => {
		setIsLoadingAnswer(true)
		videoAPI.answerComment(parentId, answer, videoId)
			.then((res) => {
				setAnswer("")
				setCommentModeId(null)
				setIsLoadingAnswer(false)
				loadCommeents()
			})
			.catch((error) => {
				setIsLoadingAnswer(false)
				console.error('Post Answer Error: ', error)
			})
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadCommeents, [videoId])

	useEffect(() => {
		if (text === "") {
			setButtonDisabled(true);
		} else {
			setButtonDisabled(false);
		}
	}, [text])

	const formatDate = (apiDate) => {
		const currentDate: any = new Date();
		const commentDate: any = new Date(apiDate);

		const timeDifferenceInSeconds = Math.floor((currentDate - commentDate) / 1000);

		if (timeDifferenceInSeconds < 300) {
			return "Just now";
		} else if (timeDifferenceInSeconds < 3600) {
			const minutesAgo = Math.floor(timeDifferenceInSeconds / 60);
			return `${minutesAgo} minute${getPluralForm(minutesAgo)} ago`;
		} else if (timeDifferenceInSeconds < 86400) {
			const hoursAgo = Math.floor(timeDifferenceInSeconds / 3600);
			return `${hoursAgo} hour${getPluralForm(hoursAgo)} ago`;
		} else if (timeDifferenceInSeconds < 2592000) {
			const daysAgo = Math.floor(timeDifferenceInSeconds / 86400);
			return `${daysAgo} day${getPluralForm(daysAgo)} ago`;
		} else if (timeDifferenceInSeconds < 31536000) {
			const monthsAgo = Math.floor(timeDifferenceInSeconds / 2592000);
			return `${monthsAgo} month${getPluralForm(monthsAgo)} ago`;
		} else {
			const yearsAgo = Math.floor(timeDifferenceInSeconds / 31536000);
			return `${yearsAgo} year${getPluralForm(yearsAgo)} ago`;
		}
	}

	const getPluralForm = (number) => {
		return number !== 1 ? "s" : "";
	}

	return (
		<Row style={{ marginBottom: 40 }} gutter={[10, 20]}>
			<Col lg={{ span: 24 }}>
				<Row align={'middle'}>
					<Col xs={{ span: 24 }} lg={24}>
						<Row align={"middle"} justify={'start'}>
							{/* <Col xs={5} lg={2}>
								<div className={styles.iconWrapper}>
									{
										avatar ?
											<img className={styles.avatar} src={avatar} alt="" />
											:
											<Avatar size={64} icon={<UserOutlined />} />
									}
								</div>
							</Col> */}

							<Col span={22}>
								<Input.TextArea
									showCount
									rows={1}
									maxLength={256}
									className={styles.comment_area}
									placeholder="Add a comment"
									onChange={(e) => {
										setText(e.target.value)
									}}
									style={{ height: 100, resize: 'none' }}
									value={text}
									autoSize
									onPressEnter={handlePostComment}
									onClick={() => setCommentModeId(null)}

								/>
							</Col>

							<Col span={1}>
								<div className={styles.controls}>
									<Button
										type="link"
										onClick={() => {

											setCommentModeId(null)
											setShowPickerA(false)
											setShowPicker((val) => !val)
										}
										}
										className={styles.custom_buttons}
										style={{ padding: "0 10px" }}>

										<Mood style={{ fill: "#fff" }} />
									</Button>
									<Button
										type="link"
										style={{ padding: 0 }}
										icon={isLoading ? <LoadingOutlined style={{ fill: "#fff" }} /> : <Send style={{ fill: "#fff" }} />}
										disabled={isLoading || buttonDisabled}
										onClick={handlePostComment}
										className={styles.custom_buttons}
									>

									</Button>
								</div>
							</Col>
						</Row>
					</Col>
					{
						showPicker && (
							<Col>
								<Picker
									onEmojiClick={(event) => {
										setText((prev) => prev + event.emoji);
										setShowPicker(false);
									}} />
							</Col>
						)
					}
				</Row>
			</Col>
			<Col xs={24}>
				{!!comments.length && <Tree
					className={styles.tree}
					showLine
					switcherIcon={false}
					defaultExpandAll
					treeData={comments}
					selectable={false}
					titleRender={(props: any) => {
						return <div key={props.id}>
							<Row>
								<Col className={styles.avatar_col}>

									{

										props.avatar ?
											<div className={styles.commentIconWrapper}>
												<img
													className={styles.icon}
													src={props.avatar}
													alt={"ava"}
													width={40}
													style={{ borderRadius: "50%" }}
												/>
											</div>
											:
											<Avatar className={styles.avatar_blank} icon={<UserOutlined />} />
									}


								</Col>

								<Row style={{ padding: 5, flexDirection: 'column' }}>
									<Row align={'middle'}>

										<Col>
											<Space direction={"vertical"} >
												<h4 className={styles.name}>{props.nickname}</h4>

											</Space>
										</Col>
										<Col>
											<p className={styles.date}>{formatDate(props.created_at)}</p>
										</Col>
									</Row>
									<Row>
										<Col span={24}>
											<p className={styles.text}>{props?.text}</p>
										</Col>
									</Row>
									<Row>
										{
											commentModeId !== props.id && (
												<Row align={'middle'} className={styles.actions}>
													<Col>
														<Button
															type="link"
															className={styles.actions_rate_like}
															onClick={
																() => {
																	handlePostLike(props.id)
																}
															}
														>
															{
																props.vote === "like" ?
																	<LikeFilled />
																	:
																	<LikeOutlined />
															}
															<span>{props.vote_counters?.like}</span>
														</Button>
													</Col>
													<Col>
														<Button
															type="link"
															className={styles.actions_rate_dislike}
															onClick={() => handlePostDislike(props.id)}
														>
															{
																props.vote === 'dislike'
																	?
																	<DislikeFilled />
																	:
																	<DislikeOutlined />
															}
															<span>{props.vote_counters?.dislike}</span>
														</Button>
													</Col>
													<Col style={{ display: "flex", alignItems: "center" }}>
														<Button
															type={"link"}
															onClick={() => onSelect(props.id)}
															className={styles.reply}
														>
															Reply
														</Button>
													</Col>
												</Row>

											)}
										{
											commentModeId === props.id && (
												<Col span={24} className={styles.reply_field}>
													<Row gutter={[5, 5]}>
														<Col span={16}>
															<Input
																value={answer}
																width={"80%"}
																onChange={(e) => {
																	setAnswer(e.target.value)
																}}
																onPressEnter={() => handleAnswerComment(props.id)}
															/>
														</Col>
														<Col>
															<Button
																type="link"
																className={styles.controlBtn_reply}
																onClick={() => {
																	setShowPicker(false)
																	setShowPickerA((val) => !val)
																}
																} style={{ padding: 3 }}>
																<Mood />
															</Button>
														</Col>
														<Col span={4} style={{ display: "flex", gap: 5 }}>
															<Button
																type="link"
																className={styles.controlBtn_reply}
																icon={isLoadingAnswer ? <LoadingOutlined /> : <SendOutlined color="#fff" />}
																disabled={isLoadingAnswer}
																onClick={() => handleAnswerComment(props.id)}
															>
															</Button>
															<Button
																className={styles.controlBtn_reply}
																style={{ paddingTop: 5 }}
																icon={<CloseOutlined color="#fff" />}
																onClick={() => setCommentModeId(null)}
															/>
														</Col>
														{
															showPickerA && (
																<Col span={24}>
																	<Picker style={{ color: "#fff" }} onEmojiClick={(event) => {
																		setAnswer((prev) => prev + event.emoji);
																		setShowPickerA(false);
																	}} />
																</Col>
															)
														}
													</Row>
												</Col>
											)
										}
									</Row>
									<Divider className={styles.divider} />

								</Row>
							</Row>
						</div>
					}}
				/>}
			</Col>
		</Row>
	)
}

export default Comments;
