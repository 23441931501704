import { useEffect, useState } from 'react';

import { statisticsAPI } from '../../api/api';
import { HeaderGeneral } from '../../components/HeaderGeneral/HeaderGeneral';
import { TopWinnersList } from '../../components/TopWinnersList/TopWinnersList';
import { TopPlayers } from '../../components/WinnersPages/TopPlayers/TopPlayers';

import styled from './Winners.module.scss';
import { ProfileLayout } from '../../components/ProfileLayout/ProfileLayout';
import { Button, Col, List, Row } from 'antd';
import DetailsProfile from '../../components/Profile/DetailsProfile';
import { useAppSelector } from '../../hooks/reduxHooks';
import TopProducers from '../../components/WinnersPages/TopProducers/TopProducers';
import TopSponsors from '../../components/WinnersPages/TopSponsors/TopSponsors';
import { useMediaQuery } from 'react-responsive';
import { HeaderTournament } from '../../components/HeaderTournament/HeaderTournament';
import MusicHeader from '../../components/MusicHeader';
import mockImage from '../../assets/mock/battle.jpg'
import HallOfFame from '../../components/HallOfFame';

const Winners = ({ onlyResults }: { onlyResults?: boolean }) => {
  const mock = [
    { id: 1, name: "David", points: 60, status: "Silver", image: mockImage },
    { id: 2, name: "Elizabeth", points: 52, status: "Silver", image: mockImage },
    { id: 3, name: "John", points: 47, status: "Bronze", image: mockImage },
  ]
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const [data, setData] = useState<any>()

  useEffect(() => {
    statisticsAPI
      .getTopPlayers(10, 1)
      .then((res) => setData(res.data))
  }, [])



  return (
    <>
      {!isDesktop && <MusicHeader title='Hall of Fame' primaryColor='#FFD300' color='#fff' />}


      <div className={styled.wrapper}>
        <List
          pagination={
            { position: 'bottom', align: 'center' }
          }

          dataSource={data}
          renderItem={(item: any, index) => (
            <HallOfFame index={index + 1} name={item.nickname} points={item.wins_amount} status={item.player_status} image={item.avatar} />
          )}
        />



      </div>
    </>
  );
};

export { Winners };
