import { Col, Row } from 'antd';

import styled from './Privacy.module.scss';
import MusicHeader from '../../components/MusicHeader';
import { useMediaQuery } from 'react-responsive';

const Privacy = () => {

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });


  return (
    <div className={styled.wrapper}>
      {
        !isDesktop && <MusicHeader title='PRIVACY POLICY' primaryColor='#0E664B' color='#fff' />
      }

      <Row className={styled.container}>
        <Col span={24}>
          <p className={styled.text}>
            INTRODUCTION
          </p>
          <p className={styled.text}>
            FantasyBattles Ltd. (hereinafter referred to as "we", "us", or "our") operates the website Music.bet (hereinafter referred to as "Music.bet") and is the controller of information collected or provided through the Websites and their affiliates.
            Please read this privacy policy carefully, as your access to and use of our Websites indicates that you have read and understand all the terms of this privacy policy. We respect your privacy and are committed to protecting your personal data.
            If you have any questions about our privacy practices, please refer to the "Contact Information" section below to learn how to reach us.

          </p>

          <p className={styled.text}>SCOPE</p>
          <p className={styled.text}>For the purposes of this privacy policy, "processing", "processed", or "process" means any operation or set of operations performed on personal data or sets of personal data (excluding disclosure), whether or not performed by automated means, including one or more of the following operations: collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, alignment or combination, restriction, erasure, or destruction. Information about the disclosure of personal data or sets of personal data can be found in the "Disclosure of Your Personal Information" section below.</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>This privacy policy applies to the information we collect:</p>
          <ul className={styled.list}>
            <li>On the Websites and in your electronic communications with the Websites,</li>
            <li>Through our mobile applications, which provide interaction with the Websites without using a browser, or </li>
            <li>When you interact with our advertisements and applications on third-party websites and services, if such applications or advertisements contain links to this privacy policy.</li>
          </ul>

          <p className={styled.text}>DATA WE PROCESS ABOUT YOU</p>
          <p className={styled.text}>We may process various types of personal data about you depending on whether you have created an account with us.</p>
          <p className={styled.text}>Individuals who visit the Websites without logging in or registering ("unregistered users"):</p>
          <p className={styled.text}>Contact Information: We process email addresses or any other information you voluntarily provide to us for a specific function, such as entering a contest or survey. In some cases, we process usernames or similar identifiers and email addresses as part of verifying that you are of the legal age required to access the Websites and their content.  </p>
          <p className={styled.text}>Website Activity Data: We process information on how you use our Websites, products, and services, interact with our content and advertisements, including the pages you visit, search history, and the referring web page from which you arrived at our site. We process browser and operating system information, devices you use to access the Websites, and your time zone settings. We also process online identifiers, including IP addresses, and set cookies as explained below in the section "Cookies and Automatic Data Processing Technologies." </p>
          <p className={styled.text}>Identifiers: Some users may be asked to provide identifiers (including government-issued photo identification and other documentation) to verify that they are of the legal age required to access the Websites and their content. In such cases, this information is processed by trusted third-party age verification service providers. This policy does not cover the privacy practices of these third-party age verification service providers. Please review the privacy terms of these service providers carefully.</p>
          <p className={styled.text}>Contact Information: We process email addresses or any other information you voluntarily provide to us for a specific function, such as entering a contest or survey. In some cases, we process usernames or similar identifiers and email addresses as part of verifying that you are of the legal age required to access the Websites and their content.  </p>
          <p className={styled.text}>Website Activity Data: We process information on how you use our Websites, products, and services, interact with our content and advertisements, including the pages you visit, search history, and the referring web page from which you arrived at our site. We process browser and operating system information, devices you use to access the Websites, and your time zone settings. We also process online identifiers, including IP addresses, and set cookies as explained below in the section "Cookies and Automatic Data Processing Technologies."  </p>
          <p className={styled.text}>Identifiers: Some users may be asked to provide identifiers (including government-issued photo identification and other documentation) to verify that they are of the legal age required to access the Websites and their content. In such cases, this information is processed by trusted third-party age verification service providers. This policy does not cover the privacy practices of these third-party age verification service providers. Please review the privacy terms of these service providers carefully.</p>
          <p className={styled.text}>Individuals who choose to create an account on Music.bet: </p>
          <p className={styled.text}>We process the same categories of information for registered users as for unregistered users.</p>
          <p className={styled.text}>Contact Information: We process usernames or similar identifiers and email addresses.</p>
          <p className={styled.text}>Payment and Commercial Information: If you subscribe, make a purchase, or receive payments from us, we process payment card or account details necessary to process the payment. We also process payment information, subscriptions, and details of products or services you have purchased or received from us.  </p>
          <p className={styled.text}>User-Provided Personal Information: We process information you provide to personalize your account or for specific functions, such as your date of birth, age, gender, interests, preferences, feedback, survey responses, and any other information you voluntarily provide for specific functions.   </p>
          <p className={styled.text}>Identifiers and Biometric Information: If you choose to register for certain services on the Websites, we process the identifiers you provide (including government-issued photo identification and other documents) to verify your identity and age. We also process biometric information to verify the authenticity of the documents you provide when registering for such services. This information is processed by trusted third-party age verification service providers. This policy does not cover the privacy practices of these third-party service providers. Please review their privacy terms carefully.  </p>
          <p className={styled.text}>User Content, including Audio/Video Information: We provide areas on our Websites where you can post information about yourself and others, communicate with others, upload content (such as images, video files, etc.), and leave comments or reviews about the content on the Websites.  </p>
          <p className={styled.text}>Please exercise caution when providing user content. By providing content, you are making this information public. User content can be read, collected, used, and disclosed by others, and we cannot control who has access to your content or how other users may use the information you voluntarily provide. User content is governed by the Music.bet terms of use.   </p>
          <p className={styled.text}>We may process your data to create and distribute aggregated data that does not directly or indirectly identify you and is not associated with you. Such aggregated information is not personal information. </p>
          <p className={styled.text} style={{ margin: "10px 0" }}>Our Websites are not intended for individuals under the age of 18 or the legal age of majority in the jurisdiction from which the Websites are accessed ("minors"), and we prohibit minors from using the Websites. As stated in this privacy policy, some users may be asked to provide information to verify that they are of the legal age required to access the Websites and their content. We do not knowingly process personal information from minors. If you are a parent or legal guardian of a minor who has provided us with personal information, please contact us at support@music.bet to have that minor’s personal information deleted.</p>


          <p className={styled.text}>SOURCES FROM WHICH WE PROCESS PERSONAL INFORMATION  </p>
          <p className={styled.text} style={{ margin: "10px 0" }}>We process personal information in the following ways:  </p>
          <p className={styled.text}>Directly from you: We process the categories of information listed above that we receive directly from you.  </p>
          <p className={styled.text}>Automated Technologies or Interactions: As explained in the section below on Cookies and Automatic Data Processing Technologies, we set cookies and use other automatic methods to process website activity data when you visit our Websites or other websites owned by our corporate group.</p>


          <p className={styled.text}>PURPOSES FOR WHICH WE PROCESS YOUR PERSONAL INFORMATION</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>We process personal information for the purposes described below.</p>
          <p className={styled.text}>Provision of Services: We process identifiers, website activity data, contact information, payment and commercial information, and user materials for registered users only to present our Websites and their contents to you, including any interactive features. We provide you with information, products, or services that you request, verify your eligibility for contests and sweepstakes, and deliver prizes.  </p>
          <p className={styled.text}>Customer Management (Registered Users Only): We process identifiers, contact information, payment, and commercial information to manage registered user accounts, provide customer support, and notify registered users about account status, subscription expiration, renewal, and changes to our Websites or any products or services offered through them. We process identifiers and biometric information to verify your identity and the authenticity of the identifiers you provide when registering for certain services on the Websites.  </p>
          <p className={styled.text}>Customization of Content and Marketing (Unregistered and Registered Users Only): We process identifiers, contact information, website activity data for both unregistered and registered users, and personal information and user content for registered users to analyze your use or interests in our Websites’ content, products, or services to develop and display content and advertising relevant to your interests.  </p>
          <p className={styled.text}>Analytics: We process identifiers and website activity data to determine whether users of our Websites are unique or whether the same user is using the Websites multiple times. We also monitor aggregate metrics, such as total visitors, pages viewed, and demographic patterns.  </p>
          <p className={styled.text}>Functionality and Security: We may process any of the categories of data we collect to diagnose or fix technical issues, verify your payment information, and detect, prevent, and respond to fraud, illegal activities, or intellectual property infringement.  </p>
          <p className={styled.text}>Compliance: We may process any category of data to enforce our terms and comply with legal obligations. In some jurisdictions, this includes our legal obligation to verify the age of users who access our Websites.  </p>
          <p className={styled.text}>We will process contact information and user-provided personal information in any other way we may describe when you provide the information (e.g., for a contest) or for any other purpose with your consent provided separately from this privacy policy.</p>


          <p className={styled.text}>OUR LEGAL BASES UNDER CANADIAN AND EUROPEAN UNION (EU) PRIVACY LAW</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>We have the following legal bases under Canadian and EU privacy law for processing your personal data for the purposes described in the "Purposes for Which We Process Your Personal Information" section:</p>
          <p className={styled.text}>We process personal data to provide services, manage customer accounts, certain customization of content (e.g., based on your selected preferences, favorites, and ratings), and functionality and security, as processing is necessary for the performance of a contract. Specifically, it is necessary to provide the requested services or products or to provide our Websites and services consistent with our terms and any other contract with you.  </p>
          <p className={styled.text}>We process personal data, including identifiers, to verify your identity, as such processing is necessary to comply with legal or regulatory obligations.  </p>
          <p className={styled.text}>We process biometric information based on your consent.  </p>
          <p className={styled.text}>We process personal data for advertising, marketing, and analytics based on our legitimate interests. </p>
          <p className={styled.text}>We process personal data for functionality and security because it is necessary for our legitimate interests and, in some cases, because processing is necessary to comply with legal or regulatory obligations.  </p>
          <p className={styled.text}>We process personal information in specific circumstances where you have provided consent for such processing.</p>

          <p className={styled.text}>DISCLOSURE OF YOUR PERSONAL INFORMATION</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>We disclose personal information as follows:</p>
          <p className={styled.text}>To the Public: When you submit user content, including audio/video materials, you use our Websites and services to make this information public.  </p>
          <p className={styled.text}>Within Our Corporate Group: We may disclose any categories of personal information to members of our corporate group (including affiliates and related companies) as necessary for the purposes outlined above.  </p>
          <p className={styled.text}>Service Providers: We disclose categories of personal information we process to authorized service providers who perform specific services on our behalf. These services may include payment processing, order fulfillment, identity and document verification (including biometric processing), age verification, risk and fraud detection, customer service, marketing, content customization, analytics, security, or hosting and supporting our Websites' functionality. These service providers may have access to personal information necessary for their functions but are not permitted to process this information for other purposes.  </p>
          <p className={styled.text}>Legal Successors: We may disclose all categories of personal information we process to a buyer or successor in the event of a merger, acquisition, sale, or transfer of some or all of our assets, whether as part of a continuing business or as part of bankruptcy, liquidation, or similar proceedings.  </p>
          <p className={styled.text}>To Comply with the Law or Protect Our Rights or the Rights of Third Parties: We access, preserve, and share all categories of personal information with regulators, law enforcement, or others where we reasonably believe such disclosure is needed to: (a) comply with any applicable law, regulation, legal process, or government request; (b) enforce applicable terms of use, including investigating potential violations; (c) detect, prevent, or address illegal or suspected illegal activities, security or technical issues; (d) protect against harm to the rights, property, or safety of our company, users, employees, or others; or (e) maintain and protect the security and integrity of our Websites or infrastructure. In such cases, we may raise or waive any legal objections or rights available to us at our discretion.</p>

          <p className={styled.text}>COOKIES AND AUTOMATIC DATA COLLECTION TECHNOLOGIES</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>
            When you navigate and interact with our Websites, we use automatic data collection technologies to collect information about website activity.
          </p>
          <p className={styled.text}>We use cookies, which are small text files stored in your web browser or downloaded to your device when you visit a website</p>
          <p className={styled.text}>We currently use the following types of cookies, set by our Websites' domains or other domains we own or control:  </p>

          <ul className={styled.list}>
            <li>Strictly Necessary Cookies: These cookies are necessary for the operation of our Websites. For example, they allow users to log in and check whether they have access to specific services or content.</li>
            <li>Functionality Cookies: These cookies help personalize and enhance your online experience on our Websites. They allow us to recognize you when you return to our Websites and remember, for example, your language preferences.</li>
            <li>Analytics Cookies: These cookies allow us to recognize and count users and see how users interact with and explore our Websites. These cookies help improve our Websites by ensuring users easily find what they are looking for. We also use third-party session recording technologies to better understand user experiences, with data being pseudonymized.</li>
            <li>Targeting and Advertising Cookies: These cookies record user visits to our Websites, the pages viewed, and the links followed to make our Websites more relevant to users' interests and serve ads that may interest them. These cookies are used for both unregistered and registered users.</li>
          </ul>
          <p className={styled.text}>Analytics, Functionality, and Targeting/Advertising cookies can be turned off anytime by accessing the "Manage cookies" option at the footer of our website. However, declining these cookies may disable some website functionalities and disrupt access to certain parts of our Website.  </p>
          <p className={styled.text}>Cookies may be either session or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete it. Expiration dates are set within the cookies; some may expire after a few minutes, others may last for years.  </p>
          <p className={styled.text}>Do Not Track: Our systems do not recognize browser "Do Not Track" signals.  </p>
          <p className={styled.text}>Use of Google Analytics: We use Google as a service provider to collect and analyze information on how users use our Websites, including through first-party cookies set by our domains and third-party cookies set by Google. Since we enabled IP anonymization for Google Analytics, Google anonymizes the last octet of an IP address and does not store your full IP address. Google uses this information only to provide Google Analytics services to us and will not use it for other purposes. Information collected by Google Analytics may be transferred to and stored by Google on servers in the United States under standard contractual clauses approved by the EU. You can learn more about how Google uses data here, and you can opt-out of Google Analytics by visiting the Google Analytics opt-out page or by accepting only necessary cookies.</p>

          <p className={styled.text}>
            Analytics, Functionality and Advertising / Targeting cookies can be switched off at any time by accessing the ‘Manage cookies’ option that is located at the footer of our website. However, if you decline to accept these cookies, some functionality on our Websites may be disabled and can interrupt your access to certain parts of our Website.<br />
            Cookies can be either session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years.<br />
            Do Not Track: Our systems do not recognize browser “Do Not Track” signals.<br />
            Use of Google Analytics. We use Google as a service provider to collect and analyze information about how users use the Websites, including, by collecting website activity data through first-party cookies set by our domains, and third-party cookies set by Google. Because we activated IP anonymization for Google Analytics, Google will anonymize the last octet of a particular IP address and will not store your full IP address. Google will use the information only for the purpose of providing Google Analytics services to us and will not use this information for any other purposes. The information collected by Google Analytics may be transmitted to and stored by Google on servers in the United States pursuant to standard contractual clauses approved by the EU. You can learn more on how Google uses data <a href="https://policies.google.com/technologies/partner-sites">here</a>, and you can opt-out of Google Analytics by visiting the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics opt-out page</a> or by accepting only necessary cookies.
          </p>

          <p className={styled.text}>THIRD-PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES</p>
          <p className={styled.text}>Some content or applications, including advertisements on Music.bet, are provided by third parties. These third parties may use cookies alone or with other tracking technologies to collect information about you when you click, view, access, or use any third-party features on Music.bet or when directed to such third-party websites. Unless expressly stated otherwise, Music.bet does not provide personal information to these third parties, but when you click on third-party internet-based advertising, they may collect information, including your IP address, the advertisements you click, time zone settings, location, and information about your browser, operating system, and devices used to access Music.bet, to provide targeted content.  </p>
          <p className={styled.text}>You can set your browser to refuse all third-party cookies or alert you when cookies are sent.</p>
          <p className={styled.text}></p>

          <p className={styled.text}>YOUR CHOICES ABOUT HOW WE COLLECT, USE, AND DISCLOSE YOUR PERSONAL INFORMATION  </p>
          <p className={styled.text} style={{ margin: "10px 0" }}>We aim to provide you with choices regarding the personal information you provide to us.</p>
          <p className={styled.text}>You can choose not to provide certain personal information, but that may prevent you from using certain features of our Websites, as such information may be required to register as a member, subscribe, purchase products or services, enter a contest, promotion, survey, or sweepstakes, ask a question, or initiate other transactions on our Websites.  </p>
          <p className={styled.text}>The Cookie Banner can be used to set your cookie preferences. The Cookie Banner records your consent for our cookies. For logged-in users, we will ask for consent once a year or when there are significant changes to our cookie policy to ensure you stay informed of updates to our cookie and privacy policies. For non-logged-in users, your Cookie Banner selection will only be saved for the specific session. The Cookie Banner controls the Functionality, Analytics, and Advertising cookies on our website. Strictly necessary cookies cannot be disabled, and the tool cannot be used to block cookies on third-party websites linked from our site.  </p>
          <p className={styled.text}>You can access the Cookie Banner anytime by clicking the "Manage cookies" option at the footer of our website.  </p>
          <p className={styled.text}>You can opt-out of receiving marketing emails from us by using the opt-out link provided in our emails or by adjusting your preferences in your account settings. If you are a registered user, we may continue to send you other types of important emails, such as those related to transactions, accounts, or orders.  </p>
          <p className={styled.text}>You can use your account settings to delete your user content and audio/video information.  </p>
          <p className={styled.text}>You can also delete and deactivate your account with us at any time unless prohibited by law or a court order. In that case, your profile will no longer be accessible. If you later choose to create an account with us, you will need to sign up for a new account, as none of your previously provided or saved information will be retained.</p>



          <p className={styled.text}>YOUR RIGHTS RELATED TO YOUR PERSONAL INFORMATION</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>In some regions, such as Canada, the European Economic Area (EEA), and the United Kingdom, you have specific rights regarding your personal information, including the right:</p>
          <ul className={styled.list}>
            <li>To access information we hold about you and understand how such personal information is processed (right to access);  </li>
            <li>To correct inaccurate personal information concerning you (right to rectification);  </li>
            <li>To delete your personal information (right to deletion or "right to be forgotten");  </li>
            <li>To receive your personal information in a structured, commonly used, and machine-readable format and to transmit it to another data controller (right to data portability);  </li>
            <li>To object to the processing of your personal information when such processing is based on our legitimate interests or public interests (right to object);  </li>
            <li>In some cases, to restrict our processing of your personal information (right to restrict processing); and  </li>
            <li>To withdraw your consent at any time when processing is based on consent (right to withdraw consent).  </li>
          </ul>
          <p className={styled.text}>
            You may exercise your right to access and deletion using the buttons at the bottom of this page. You may also email us at support@music.bet to exercise your rights per applicable legal requirements and limitations. If you are located in the EEA or the UK, you have the right to lodge a complaint with your local data protection authority.
          </p>
          <p className={styled.text}>Please note that if you do not have an account with us, we may not have enough information to identify you and may not be able to respond to your request. Additionally, in some cases, to verify your identity or authorization to make the request, we may require additional information.  </p>
          <p className={styled.text}>Note that some requests to delete personal information will require the deletion of your account, as providing user accounts is inextricably linked to the processing of certain personal information (e.g., your email address).</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>California Rights and Choices  </p>
          <p className={styled.text}>The California Consumer Privacy Act (CCPA) provides you with certain rights related to your personal information:  </p>
          <ul className={styled.list}>
            <li>Right to Know: You have the right to request that we disclose specific information about our processing of your personal information over the past 12 months, including the exact pieces of information we processed.</li>
            <li>Right to Request Deletion: You have the right to request that we delete any of your personal information that we have processed and retained, subject to certain exceptions outlined in the CCPA.  </li>
            <li>Right to Non-Discrimination: We will not discriminate against you for exercising any of your rights under the CCPA.  </li>
          </ul>

          <p className={styled.text}>To exercise the rights described above, please use the buttons at the bottom of this page or email us at support@music.bet with the subject line "CCPA Request." In either case, you will need to provide the following information to verify your identity and allow us to locate your information in our systems: your username and email address used to create your account, as well as any other information we may reasonably request to verify your identity. We may require you to verify access to your account and/or the email account used to register with us.  </p>
          <p className={styled.text}>You may designate an agent to make a request by executing a notarized power of attorney, allowing that person to act on your behalf and providing that person with the information listed above that enables us to verify your identity and locate your information. Alternatively, you will need to directly confirm your identity with us using the methods described above, sign an authorization for the agent to act on your behalf, and provide us with confirmation that you have done so.  </p>
          <p className={styled.text}>We disclose certain categories of California residents' personal information for business purposes, as described in the section "Disclosure of Your Personal Information" above.</p>
          <p className={styled.text}>Residents' Rights – U.S. Jurisdictions, Where Applicable  </p>
          <p className={styled.text}>We do not sell, trade, or exchange registered users' personal information with anyone for money.</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>The California Consumer Privacy Act (“CCPA”) provides you certain rights in relation to your personal information:</p>

          <p className={styled.text}>TRANSFER OF YOUR PERSONAL INFORMATION TO OTHER COUNTRIES  </p>
          <p className={styled.text} style={{ margin: "10px 0" }}>
            Where permitted by your country's laws, by using the Websites, you consent to the transfer of information we process about you, including personal information, to other countries where we, members of our corporate group (including affiliates and related entities), or our service providers are located. When we transfer personal information outside the European Economic Area (EEA) or other regions with comprehensive data protection laws, we ensure that the information is transferred in accordance with applicable laws. Where relevant, our transfers outside the EEA are made under standard contractual clauses approved by the European Union.
          </p>

          <p className={styled.text}>RETENTION OF PERSONAL INFORMATION</p>
          <p className={styled.text}>
            We will only retain your personal information as long as your account is active or necessary to fulfill the purposes for which we processed it, including meeting legal, accounting, or reporting requirements.
          </p>
          <p className={styled.text}>
            To determine the appropriate retention period for personal data, we consider several factors, including what personal data we are processing, the risk of harm from unauthorized disclosure, why we are processing your data, and whether we can achieve this outcome by other means without processing it.
          </p>
          <p className={styled.text}>
            When we no longer need to process your personal information for the purposes outlined in this Privacy Policy, we will delete it from our systems.</p>
          <p className={styled.text}>
            Where permissible, we will also delete your personal information upon request, as described in the section "Your Rights Related to Your Personal Information."</p>
          <p className={styled.text} style={{ margin: "10px 0" }}>
            Third-Party Links and Sites  <br />
            If you click on a third-party link, you will be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Please carefully read the privacy policy of other websites. We are not responsible for third-party practices.
          </p>
          <p className={styled.text}>CHANGES TO OUR PRIVACY POLICY</p>
          <p className={styled.text}>We may modify or revise our privacy policy from time to time. If we change anything in our privacy policy, the date of change will be reflected in the “last modified date”. We may attempt to notify you of any material changes as required by law. Please also periodically review the most up-to-date version of our privacy policy, which will be posted at this location, so you are aware of any changes.</p>

          <p className={styled.text}>JURISDICTION</p>
          <p className={styled.text}>
            The Services, including the Site, is made available by us from Cyprus and is not intended to subject us to the laws or jurisdiction of any state, country or territory other than that of Cyprus.
          </p>

          <p className={styled.text}>CONTACT INFORMATION  </p>
          <p className={styled.text}>If you have any questions about this privacy policy or our information-handling practices, please contact us at support@music.bet.  <br />
            Our Data Protection Officer can be reached at the following email address: support@music.bet.
          </p>

          <p className={styled.text}>MANAGE MY DATA</p>
          <p className={styled.text}>Per Data Protection Laws in users' jurisdictions, Music.bet registered users may request a copy of their personal data and request Music.bet to delete their data.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export { Privacy };
