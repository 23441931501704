import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { Login } from './Login';
import { authAPI } from '../../api/api';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeLoginModalMenu, toggleLoginScene } from '../../store/loginModalSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import MusicHeader from '../../components/MusicHeader';
import { Registration } from './Registration';
import { SignIn } from './SignIn';


interface IInitial {
  email?: string;
}

const ForgottenPassword = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [authType, setAuthType] = useState("");


  function navigateToSignIn() {
    dispatch(toggleLoginScene(null));
  }

  function navigateToCreateAccount() {
    dispatch(toggleLoginScene(null));
  }

  function logInData(values: IInitial) {
    authAPI
      .forgottenPassword(values.email)
      .then(() => {
        toast.success('Success');

      })
      .then(() => {
        console.log('then called');
      })
      .then(() => {
        if (localStorage.getItem('isAuth')) {
          navigate('/genres');
        }
        dispatch(closeLoginModalMenu(null));
      })
      .catch((error) => {
        toast.error('Something went wrong, please try again');

      });
  }

  return (
    <>
      {/* <NotificationT /> */}

      {authType === "" ?
        <>
          <MusicHeader title='Forgotten password' primaryColor='#A8908D' fontSize={21} />
          <Form
            layout="vertical"
            onFinish={(values) => {
              logInData(values)
            }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%", marginTop: 40 }}
          >
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "insert a correct email",
                },
              ]}
            >
              <Input placeholder='E-mail*' />
            </Form.Item>
            <div className={styled.btn}>

              <Button
                htmlType="submit"
                type={"default"}
                className={styled.btn2}

              >
                <span className={styled.submitBtn}>
                  Send
                </span>
              </Button>

              <Button
                onClick={() => setAuthType("create")}
                type={"default"}
                className={styled.btn1_forgot}
              >
                Create new account
              </Button>

              <Button
                onClick={() => setAuthType("login")}
                type={"default"}
                className={styled.btn1_forgot}
              >
                Have an Account? Login
              </Button>

            </div>
          </Form>
        </>
        :
        authType === 'create' ?
          <Registration />
          :
          <SignIn />
      }
    </>
  )
};

export { ForgottenPassword };
