/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { Row, Col, Divider, List } from 'antd';

import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { HeaderGeneral } from '../../components/HeaderGeneral/HeaderGeneral';
import { BattleItem } from '../../components/BattleList/BattleItem.tsx/BattleItem';

import { battleAPI, musicAPI } from '../../api/api';

import styled from './NTFBattles.module.scss';

export type round = {
    name: string;
    isActive: boolean;
};



const NFTBattles = () => {
    const [catName, setCatName] = useState("")
    const [searchParams] = useSearchParams();

    const [battles, setBattles] = useState<any>([])
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    const [per, setPer] = useState<number>(3)
    const [page, setPage] = useState<number>(1)
    let category_id: any = searchParams.get('category_id');

    const loadMoreData = (category_id: number, per: number, page: number) => {
        if (loading) {
            return;
        }

        setPage(page + 1)
        setLoading(true);
        battleAPI
            .showBattleWithFilter(category_id, per, page)
            .then((body) => {
                setCount(body.count)
                setBattles([...battles, ...body.data]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadMoreData(category_id, per, page);
    }, [])

    useEffect(() => {
        musicAPI.getCategories().then(categoriesResponse => {
            setCatName(categoriesResponse.find(el => el.id === +category_id)?.name);
        });
    }, []);

    return (
        <>
            <Row>
                <Col span={24}>
                    <div className={styled.battleHead}>
                    <HeaderGeneral title='Battles' />
                    </div>

                    {/* <NotificationT /> */}
                </Col>
            </Row>
            <div
                id="scrollableDiv"
                style={{
                    height: battles.length > 0 ? '80vh' : "100%",
                    overflow: "auto",
                }}
            >

                <div className={styled.bg}>
                    <div className={styled.flexMainContainer}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <div className={styled.info}>
                                {!!catName && <h2>Category / {catName} battles</h2>}
                            </div>
                        </div>
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={battles.length}
                    // @ts-ignore
                    next={() => loadMoreData(category_id, per, page)}
                    hasMore={battles.length < count}
                    loader={<div >Loading ...</div>}
                    endMessage={<Divider plain>to be continued...</Divider>}
                    scrollableTarget="scrollableDiv"
                >
                    <List
                        dataSource={battles}
                        renderItem={({ data: battle }: any) => {
                            return (
                                <List.Item key={battle.id} className={styled.list} style={{ display: "flex", justifyContent: "center", marginBottom: 20, padding: 20, borderBlockEnd: "1px solid rgba(0, 0, 0, 0.3)" }}>
                                    {/* <BattleItem
                                        battleId={battle.id}
                                        winnerCustomerId={battle.winner_customer_id}
                                        battleEndTime={battle.date_end}
                                        key={battle.id}
                                        battle={battle}
                                        idxBattle={battle.id}
                                        currentBattleIdx={battle.id}
                                        categoryId={category_id}
                                        withoutPlay
                                    /> */}
                                </List.Item>
                            )
                        }}
                    />
                </InfiniteScroll>
            </div>
        </>
    );
};

export { NFTBattles };
