import { useEffect } from "react";

import { Button, Col, ConfigProvider, Row } from "antd"
import logo from '../../assets/musicbet/MusicbetLogo.svg';

import { useAppDispatch } from "../../hooks/reduxHooks";
import { profileAPI } from "../../api/api";
import { setUserData } from "../../store/playerSlice";
// import { userLogOut } from "../../store/isAuthSlice";
import s from './Account.module.scss'
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";


type AccountType = {
	isAuth: boolean
}



const Account = ({ isAuth }: AccountType) => {

	const dispatch = useAppDispatch();

	const isMobile = useMediaQuery({
		query: "(max-width: 430px)",
	});

	const navigate = useNavigate()

	useEffect(() => {
		function ccc() {
			profileAPI
				.getProfile()
				.then((res) => {
					dispatch(
						setUserData({
							first_name: res?.first_name,
							last_name: res?.last_name,
							nickname: res?.nickname,
							email: res.email,
							gender: res.gender,
							phone: res.phone,
							id: res.id,
							balance: res.balance,
							avatar: res?.avatar,
							first_voted: res?.data?.first_voted,
							income: res?.transaction_sums?.income,
							expense: res?.transaction_sums?.expense,
							transactions: res?.videobet_transactions,
							stocks: res?.stocks
						})
					)
				});
		}
		ccc();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Row className={s.wrapper}>
			<Col>
				<Row align={'middle'} className={s.content}>
					<Col>
						<ConfigProvider
							theme={{
								components: {
									Select: {
										fontSize: 12,
										algorithm: true,
									},
									Button: {
										fontSize: 12,
										algorithm: true,
									}
								}
							}}
						>
							<Row style={{ marginTop: 5 }}>
								<Col className={s.logo_col}>
								<Button className={s.button_link} type="link" onClick={() => navigate(isAuth ? '/genres' : '')}>
											<img className={s.logo} src={logo} alt='logo' width={150} />
										</Button>
								</Col>
							</Row>
						</ConfigProvider>
					</Col>
				</Row>
			</Col>
		</Row>
	)
}

export default Account;
