import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { Login } from './Login';
import { authAPI, profileAPI } from '../../api/api';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { closeLoginModalMenu, toggleForgotPasswordScene, toggleLoginScene } from '../../store/loginModalSlice';
import { userLogIn } from '../../store/isAuthSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import MusicHeader from '../../components/MusicHeader';
import { ForgottenPassword } from './ForgottenPassword';
import { Registration } from './Registration';
import { closeMobileMenu } from '../../store/mobileMenuSlice';
import { setUserData } from '../../store/playerSlice';

interface IInitial {
  email: string;
  password: string;
}

const SignIn = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [authType, setAuthType] = useState("");

  function navigateToCreateAccount() {
    dispatch(toggleLoginScene(null));
  }

  function navigateToForgottenPassword() {
    dispatch(toggleForgotPasswordScene(null));
  }

  function logInData(values: IInitial) {
    authAPI
      .logIn(values.email, values.password)
      .then(() => {
        authAPI
          .authMe()
          .then(() => {
            profileAPI
            .getProfile()
            .then((res) => {
              dispatch(
                setUserData({
                  first_name: res.first_name,
                  last_name: res.last_name,
                  nickname: res.nickname,
                  email: res.email,
                  gender: res.gender,
                  phone: res.phone,
                  id: res.id,
                  balance: res.balance,
                  avatar: res?.avatar,
                  first_voted: res?.data?.first_voted,
                  income: res?.transaction_sums?.income,
                  expense: res?.transaction_sums?.expense,
                  transactions: res?.videobet_transactions,
                  stocks: res?.stocks
                })
              )
            });
          })
          .then(() => {
            localStorage.setItem('isAuth', "true")
            dispatch(userLogIn(null));
            navigate('/genres');
            dispatch(closeLoginModalMenu(null));
            dispatch(closeMobileMenu(null))
          })
          // .catch(() => {
          //   navigate("/login")
          // })
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Wrong email or password, please try again');
        } else {
          toast.error('Something went wrong, please try again');
        }
      });
  }

  return (
    <>
      {/* <NotificationT /> */}
        {
          authType === "" ?
            <>
              <MusicHeader title='Sign In' primaryColor='#A8908D' />
              <Form
                layout="vertical"
                onFinish={(values) => {
                  logInData(values)
                }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: "100%", marginTop: 40 }}
              >

                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      // type: "email",
                      message: "insert a correct email",
                    },
                  ]}
                >
                  <Input placeholder='Email*' />
                </Form.Item>
                <Form.Item
                  required
                  name={"password"}
                  rules={[{ required: true, message: "insert the password" }]}
                >
                  <Input.Password placeholder='Password*' />
                </Form.Item>
                <div className={styled.btn}>
                  <Button
                    htmlType="submit"
                    type={"default"}
                    className={styled.btn2}
                  >
                    <span>
                      Login
                    </span>
                  </Button>
                  <div style={{ width: "100%" }}>
                    <Button
                      onClick={() => setAuthType('create')}
                      type={"default"}
                      className={styled.btn1}
                    >
                      Create new account
                    </Button>
                    <Button
                      type={'default'}
                      onClick={() => setAuthType('forgot')}
                      className={styled.btn1}
                    >
                      Forgotten password
                    </Button>
                  </div>
                </div>
              </Form>
            </>
            :
            authType === 'create' ?

              <Registration />
              :
              <ForgottenPassword />}

    </>
  );
};

export { SignIn };
