import React, { useEffect, useMemo, useState } from "react";
import styles from './BattleItem.module.scss'
import mock from '../../assets/mock/battle.jpg'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input, Modal, Popover } from "antd";
import { useMediaQuery } from "react-responsive";
import { MantineProvider } from "@mantine/core";
import VideoPlayer from "../VideoPlayer";
import { setUserData } from "../../store/playerSlice";
import Vimeo from '@u-wave/react-vimeo';

import { ReactComponent as TitleDesktop } from '../../assets/musicbet/cards/desktop-menu.svg'
import { ReactComponent as SubtitleDesktop } from '../../assets/musicbet/cards/desktop-menu-sub.svg'


import { useDispatch } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { battleAPI, profileAPI, tracksAPI } from "../../api/api";
import { Fade } from "react-awesome-reveal";
import { Slide } from "react-awesome-reveal";

import { toast } from "react-toastify";

interface ItemProps {
  battle?: string,
  image?: any,
  primaryColor?: string,
  secondaryColor?: string
  id?: any
  video?: any
  artist?: any
  song?: string
  end_date?: string
  video_240_url?: string,
  video_360_url?: string,
  video_480_url?: string,
  video_720_url?: string,
  video_1080_url?: string,
  display_id?: number,
  track_id?: number,
  track?: any,
  onChange?: any
  idk?: any
}



const MusicBattleItem = (props: ItemProps) => {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const [videoData, setVideoData] = useState<any>(props.video)
  const [data, setData] = useState<any>()
  const [votesAmunt, setVotesAmount] = useState(0)
  const [bidAmount, setBidAmount] = useState(0)
  const [isPopoverOpen, setPopoverOpen] = useState(false)


  useEffect(() => {
    battleAPI.getBattleById(props.id)
      .then(data => {
        setData(data)
      })


  }, [])



  const [genre, Set] = useState<any>()

  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    localStorage.removeItem('game-rules-PLAYED')
    setIsModalOpen(false);
  }


  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const currentDate = new Date()

  const handleChange = () => {
    // Здесь логика для вызова при изменении
    props.onChange(); // вызываем функцию из родительского компонента
  };

  const bid = () => {

    tracksAPI
      .bidTrack(props.id, props.track_id, bidAmount)
      .then(() => {
        battleAPI.getBattleById(props.id)
          .then(data => {
            setData(data)
          })
      })
      .then(() => setBidAmount(0))
      .then(() => toast.success('Thank you for your bid'))
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks
              })
            )
          })
          .then(() => handleChange())
      })
      .catch(() => {
        if (endDate < currentDate) {
          toast.error('The current battle is over')
        } else {
          toast.error('Something is wrong')
        }
      })
  }


  const endDate = new Date(props.end_date);

  // Получаем день, месяц и год
  const day = String(endDate.getUTCDate()).padStart(2, '0');
  const month = String(endDate.getUTCMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = endDate.getUTCFullYear();

  // Форматируем в DD.MM.YYYY
  // const formattedDate = endDate !== undefined ? `${day}.${month}.${year}` : "???";

  const formattedDate = !isNaN((endDate.getTime())) ? `${day}.${month}.${year}` : "Coming soon";

  const onFinish = (values) => {
    const amount = parseInt(values.amount) || 0; // Получаем значение из формы
    if (amount <= 0) {
      setBidAmount(0); // Обнуляем значение, если оно <= 0
    } else {
      tracksAPI
        .bidTrack(props.id, props.track_id, amount)
        .then(() => {
          battleAPI.getBattleById(props.id)
            .then(data => {
              setData(data)
            })
        })
        .then(() => setBidAmount(0))
        .then(() => toast.success('Thank you for your bid'))
        .then(() => {
          profileAPI
            .getProfile()
            .then((res) => {
              dispatch(
                setUserData({
                  first_name: res?.first_name,
                  last_name: res?.last_name,
                  nickname: res?.nickname,
                  email: res.email,
                  gender: res.gender,
                  phone: res.phone,
                  id: res.id,
                  balance: res.balance,
                  avatar: res?.avatar,
                  first_voted: res?.data?.first_voted,
                  income: res?.transaction_sums?.income,
                  expense: res?.transaction_sums?.expense,
                  transactions: res?.videobet_transactions,
                  stocks: res?.stocks
                })
              )
            });
        })
        .then(() => {

        })
        .catch(() => {
          if (endDate < currentDate) {
            toast.error('The current battle is over')
          } else {
            toast.error('Something is wrong')
          }
        })
    }
    setBidAmount(0)
    form.resetFields()
    setPopoverOpen(false); // Закрываем поповер
  };

  const popoverContent = (
    <div className={styles.popover_amount}>
      <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
      <p>Place Your Amount</p>
      <Button onClick={() => setPopoverOpen(false)} style={{background: 'none', aspectRatio: 1, marginTop: 0, paddingTop: 0, paddingRight: 0}} type='link'>
      <CloseOutlined/>
      </Button>
      </div>
      <Form form={form} onFinish={onFinish}>
        <Form.Item
          style={{ marginBottom: 10, paddingBottom: 10 }}
          name="amount"
          rules={[{ required: true, message: 'Please input your amount!' }]}
        >
          <Input
            placeholder="1"
            min={0}
            type="number"
            step={1}
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item style={{ height: 'min-content', width: 'min-content', margin: '0 auto' }}>
          <Button className={styles.button_confirm} style={{ margin: '0 auto' }} type="primary" htmlType="submit">Confirm</Button>
        </Form.Item>
      </Form>
    </div>
  )

  const navigate = useNavigate()

  function mapArtists(artist) {
    if (artist?.length > 1) {
      return artist?.name.join(', ');
    }
    return artist?.length === 1 ? artist[0].name : null;
  }

  return (
    <>
      {/* <NotificationT /> */}
      <div onClick={location.pathname !== "/tournament/" ? () => { } : props.id === undefined ? () => { } : () => navigate(`/battle/?id=${props.id}&display=${props.display_id}`)} className={styles.wrapper}>
        <div style={{ position: "relative", zIndex: 11 }} onClick={location.pathname !== "/tournament/" ? () => showModal() : () => { }} className={styles.image_container}>
          <img className={styles.cover} src={props.image === undefined ? props.idk : props.image} alt="battle" />
        </div>
        {
          !isDesktop && location.pathname !== '/tournament/' ?

            <div>
              <Slide triggerOnce style={{ zIndex: 10, position: "relative" }} delay={1000} direction="down">
                <div style={{zIndex: 10 }} className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />

                  <h2 style={{zIndex:11}} className={styles.tournaments_title}>{location.pathname !== '/tournament/' ? `${mapArtists(props.artist)} - ${props.song}` : `BATTLE #${props?.display_id}`}</h2>
                </div>
              </Slide>
              <Slide triggerOnce style={{ zIndex: 9, position: "relative" }} delay={1500} direction="down">
                <Fade triggerOnce delay={1600}>
                  <div className={styles.small_under}>
                    <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 10 }} className={styles.subtitleDesltop} />

                    {
                      location.pathname !== "/tournament/" ?
                        <div className={styles.counter}>
                          <Button onClick={() => setBidAmount(bidAmount - 1)} disabled={bidAmount === 0 ? true : false} className={styles.counter_btns} type="link">-</Button>
                          <Popover open={isPopoverOpen} trigger={'click'} content={popoverContent}>
                            <p onClick={() => setPopoverOpen(true)} className={styles.counter_amount}>{bidAmount}</p>
                          </Popover>
                          <Button onClick={() => setBidAmount(bidAmount + 1)} className={styles.counter_btns} type="link">+</Button>
                        </div>
                        :
                        <h2>{formattedDate || "???"}</h2>
                    }
                  </div>
                </Fade>
              </Slide>
            </div>
            :
            location.pathname === '/tournament/' && !isDesktop
              ?
              <div>
                <div style={{ zIndex: 10, height: 20 }} className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />
                  <h2 className={styles.tournaments_title}  style={{zIndex: 11}}>{location.pathname !== '/tournament/' ? `${mapArtists(props.artist)} - ${props.song}` : `BATTLE #${props?.display_id}`}</h2>
                </div>

                <div style={{ height: 20, margin: "0 20%" }} className={styles.small_under}>
                <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 10 }} className={styles.subtitleDesltop} />
                  {
                    location.pathname !== "/tournament/" ?
                      <div className={styles.counter}>
                        <Button onClick={() => setBidAmount(bidAmount - 1)} disabled={bidAmount === 0 ? true : false} className={styles.counter_btns} type="link">-</Button>
                        <Popover open={isPopoverOpen}  trigger={'click'} content={popoverContent}>
                          <p onClick={() => setPopoverOpen(true)} className={styles.counter_amount}>{bidAmount}</p>
                        </Popover>
                        <Button onClick={() => setBidAmount(bidAmount + 1)} className={styles.counter_btns} type="link">+</Button>
                      </div>
                      :
                      <h2 style={{zIndex: 11, fontSize: '12px !important'}}>{formattedDate || "???"}</h2>

                  }
                </div>
              </div>
              :
              <div>
                <div className={styles.big_under}>
                  <TitleDesktop style={{ fill: props.primaryColor, zIndex: 10 }} className={styles.titleDesktop} />
                  <h2 style={{ display: 'block', zIndex: 100 }}>{location.pathname !== '/tournament/' ? `${mapArtists(props.artist)} - ${props.song}` : `BATTLE #${props?.display_id}`}</h2>
                </div>

                <div className={styles.small_under}>
                  <SubtitleDesktop style={{ fill: props.secondaryColor, zIndex: 100 }} className={styles.subtitleDesltop} />
                  {
                    location.pathname !== "/tournament/" ?
                      <div style={{zIndex: 100 }} className={styles.counter}>
                        <Button onClick={() => setBidAmount(bidAmount - 1)} disabled={bidAmount === 0 ? true : false} className={styles.counter_btns} type="link">-</Button>
                        <Popover open={isPopoverOpen}  trigger={'click'} content={popoverContent}>
                          <p onClick={() => {setPopoverOpen(true)}} className={styles.counter_amount}>{bidAmount}</p>
                        </Popover>
                        <Button onClick={() => setBidAmount(bidAmount + 1)} className={styles.counter_btns} type="link">+</Button>
                      </div>
                      :
                      <h2 style={{ display: 'block', zIndex: 100 }}>{formattedDate}</h2>
                  }
                </div>
              </div>
        }

        {
          bidAmount > 0 ?

            <div className={styles.confirm_block}>
              <Button className={styles.confirm_bet} onClick={() => bid()}>Confirm</Button>
            </div>
            : null
        }


      </div>

      <Modal style={isDesktop ? { top: 80 } : { top: '25%' }} destroyOnClose closeIcon={<Button className={styles.close_btn}><CloseOutlined className={styles.close_icon} /></Button>} width={isDesktop ? "100%" : "100%"} open={isModalOpen} onOk={handleOk} footer={null} onCancel={handleCancel}>

        {/* <MantineProvider> */}
          {/* <Pideo
            id={"game-rules"}
            preview={props.image}
            videos={{
              1080: props?.video_1080_url,
              720: props?.video_720_url,
              480: props?.video_480_url,
              360: props?.video_360_url,
              240: props?.video_240_url,
            }} /> */}

            <Vimeo
            video={props?.video_1080_url}
            className={styles.vimeo}
            style={{width: '100%', height: "100%"}}
            width={isDesktop ? 992 : 'max-content'}
            />

        {/* </MantineProvider> */}

        <div className={styles.track_info}>
          <p>
            {mapArtists(props.artist)} - {props.song}
          </p>
        </div>
      </Modal>
    </>
  )
}

export default MusicBattleItem;
