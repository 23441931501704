import ReactDOM from 'react-dom/client';
import App from './App';
import { HashRouter, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import { MoonPayProvider } from '@moonpay/moonpay-react';
import StickyHeadroom from '@integreat-app/react-sticky-headroom'
import './index.scss';
import Header from './components/Layout/Header';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
  <HashRouter>
    <Provider store={store}>
      <StickyHeadroom className={'sticky'} zIndex={99999} scrollHeight={0}>
        <Header />
      </StickyHeadroom>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>

    </Provider>
  </HashRouter>,
);
