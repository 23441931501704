import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import s from './timer.module.scss'
import { useLocation } from "react-router-dom";
import { ReactComponent as Bolt } from '../../assets/musicbet/icons/timer_play_30dp_FFFFFF_FILL1_wght400_GRAD0_opsz24.svg'

const Timer = ({ date: ms }) => {
	const date = new Date(ms)
	const location = useLocation();

	const [finishTime] = useState(date.getTime());
	const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
	const [tick, setTick] = useState(false);
	const isMobile = useMediaQuery({
		query: '(max-width: 431px)',
	});

	useEffect(() => {
		// @ts-ignore
		const diff = (finishTime - new Date()) / 1000;
		if (diff < 0) return // время вышло
		setDiff([
			Math.floor(diff / 86400), // дни
			Math.floor((diff / 3600) % 24),
			Math.floor((diff / 60) % 60),
			Math.floor(diff % 60)
		])
	}, [tick, finishTime])

	useEffect(() => {
		const timerID = setInterval(() => setTick(!tick), 1000);
		return () => clearInterval(timerID);
	}, [tick])

	return (
		<>
			{
				<Row align={'middle'} style={!isMobile ? { height: "100%" } : { height: "100%" }} className={s.timer} justify={"center"}>
					<Col span={24} className={s.options}>
						<Bolt className={s.bolt} />
						<span className={s.optionsValue}>
							<div className={s.optionsValueWrapp}>
								{`${diffDays} days ${diffH.toString().padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`}
							</div>
						</span>
					</Col>
				</Row>
			}
		</>
	)
}

export default Timer;
