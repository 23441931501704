import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProfileMenu } from '../ProfileMenu/ProfileMenu';
import { profileAPI } from '../../api/api';
import get from "lodash/get";


import styled from './ProfileLayout.module.scss';
import { Avatar, Button, Col, Input, Modal, Row, Switch, Upload } from 'antd';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { setUserData } from '../../store/playerSlice';
import { ReactComponent as Wallet } from '../../assets/musicbet/credit_card_heart.svg'
import { ReactComponent as WalletActive } from '../../assets/musicbet/credit_card_heart_active.svg'
import { ReactComponent as Contact } from '../../assets/musicbet/contact_support.svg'
import { ReactComponent as ContactActive } from '../../assets/musicbet/contact_support_active.svg'
import { ReactComponent as DeleteImage } from '../../assets/musicbet/account/1.svg'
import MusicHeader from '../MusicHeader';
import { useMediaQuery } from 'react-responsive';
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { putToS3 } from '../../utils/imgUploader';
import ImgCrop from 'antd-img-crop';
import { NotificationT } from '../ToastifyNot/NotificationToastify';



interface IProps {
  children?: any;
}

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const ProfileLayout: React.FC<IProps> = ({ children }) => {
  //const [userData, setUserData] = useState<any>({});
  const dispatch = useAppDispatch();
  const { userId, nickname, email, avatar, gender } = useAppSelector((state) => state.player);
  const [imageUrl, setImageUrl] = useState(avatar);
  const [editDisabled, setEditDisabled] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const [nick, setNickname] = useState(nickname);
  const [mail, setEmail] = useState(email);
  const [genre, setGenre] = useState<any>(gender)
  const [emailTouched, setEmailTouched] = useState(false)
  const [nickTouched, setNickTouched] = useState(false)
  const [avaTouched, setAvaTouched] = useState(false)


  console.log(avatar)

  const getProfileUpdateData = (): {
    avatar?: string;
    data: {
      gender: string;
    };
    email?: string;
    nickname?: string;
  } => {
    const updateData: {
      avatar?: string;
      data: {
        gender: string;
      };
      email?: string;
      nickname?: string;
    } = {
      data: {
        gender: genre || gender,
      },
    };

    if (emailTouched) {
      updateData.email = mail;
    }
    if (nickTouched) {
      updateData.nickname = nick;
    }
    if (avaTouched) {
      updateData.avatar = imageUrl;
    }

    return updateData;
  };


  // useEffect(() => {
  //   if (!!avatar) {
  //     setImageUrl(avatar)
  //   }
  // }, [avatar, setImageUrl])


  function editProfile(data: any) {
    profileAPI.editProfile(getProfileUpdateData())
      .then(() => {
        getUserData()
      })
      .catch((error) => console.log(error));
  }

  //const [balance, setBalance] = useState<any>(0);
  const navigate = useNavigate();
  const [isAuth, setIsAuth] = useState(true);

  const [active, setActive] = useState(null)

  const [isEditMode, setEditMode] = useState(false);

  function customRequest(info: object) {
    const file = get(info, "file", {});
    setImageUrl("")
    if (file) {
      getBase64(file, (imageUrl: any) => {
        setLoading(true);

        putToS3(imageUrl)
          .then((imgUrl: string) => {
            setLoading(false);
            setImageUrl(imgUrl);
            setAvaTouched(true)
          })
          .catch((error: any) => {
            setLoading(false);
            console.error(error);
          });
      });
    }
  }

  const confirm = useConfirm();

  function handleDelete() {
    confirm(() => setImageUrl(""), "Delete");
  }

  const handleEdit = () => {
    editProfile({ avatar: imageUrl, nickname: nick, email: mail })

  }
  const uploadButton = (
    <div>
      {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getUserData = () => {
    profileAPI
      .getProfile()
      .then((res) => {
        dispatch(
          setUserData({
            nickname: res.nickname,
            email: res.email,
            id: res.id,
            balance: res.balance,
            avatar: res?.avatar,
            first_voted: res?.data?.first_voted,
            transactions: res?.videobet_transactions,
            player_status: res?.player_status,
            giver_status: res?.giver_status,
            sponsor_status: res?.sponsor_status,
            stocks: res?.stocks,
            gender: res?.data?.gender
          })
        )
        setImageUrl(res?.avatar)
        setNickname(res.nickname)
        setEmail(res?.email)
      })
      .then((res) => {
        setEditDisabled(false)
      })

  }

  const location = useLocation();

  useEffect(() => {
    getUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await profileAPI.getProfile();
        const userData = {
          nickname: res.nickname,
          email: res.email,
          id: res.id,
          balance: res.balance,
          avatar: res?.avatar,
          first_voted: res?.data?.first_voted,
          transactions: res?.videobet_transactions,
          player_status: res?.player_status,
          giver_status: res?.giver_status,
          sponsor_status: res?.sponsor_status,
          stocks: res?.stocks,
          gender: res?.data?.gender
        };
        setImageUrl(userData.avatar)
        dispatch(setUserData(userData));
        localStorage.setItem("userData", JSON.stringify(userData)); // Сохранение данных в localStorage
        setEditDisabled(false);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };

    const savedUserData = localStorage.getItem("userData");
    if (savedUserData) {
      dispatch(setUserData(JSON.parse(savedUserData))); // Восстановление данных из localStorage
      setEditDisabled(false);
    } else {
      fetchData();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isAuth && isDesktop) {
      navigate('/login');
    }
  }, [isAuth, navigate]);

  return (
    <div>
      <Row >
        <Col span={24}>
          {!isDesktop && <MusicHeader title='Account' primaryColor='#9507FF' color='#fff' />}

        </Col>
      </Row>
      <Row justify={'space-between'} className={styled.container}>
        <Col xs={24} lg={24} xl={6} xxl={4}>
          <Row justify={'space-between'}>
            <Col span={10}>
              <div className={styled.avatar_wrapp}>
                {
                  <>
                    <Button style={isEditMode ? { display: 'block' } : { display: 'none' }} type='link' className={styled.delete_btn}><DeleteImage /></Button>
                    <ImgCrop modalClassName='crop' modalTitle={null} zoomSlider>
                      <Upload
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={customRequest}
                        disabled={isEditMode ? false : true}
                        className={styled.avatar}
                      >
                        <img src={imageUrl || avatar} alt="avatar" style={{ height: "100%", borderRadius: "50%", objectFit: "cover", left: 0, width: '100%' }} />
                      </Upload>
                    </ImgCrop>
                  </>
                }
              </div>

            </Col>
            {isDesktop ?
              null :
              <Col span={13}>
                <span className={styled.nickname}>{nickname}</span>
                <Input onChange={(e) => { setNickname(e.target.value); setNickTouched(true) }}
                  disabled={isEditMode ? false : true} placeholder={nickname} className={styled.input_edit} />
                <Input
                  onChange={(e) => { setEmail(e.target.value); setEmailTouched(true) }}
                  disabled={isEditMode ? false : true} placeholder={email} className={styled.input_edit} />
                {/* <Switch disabled={isEditMode ? false : true} onChange={(e) => e === true ? setGenre('male') : setGenre('female')} style={{ width: 100, scale: 2, background: "#00308F", marginTop: 10, marginBottom: 10 }} checkedChildren="Male" unCheckedChildren="Female" defaultChecked={gender === 'female' ? false : true} defaultValue={gender === 'female' ? false : true} /> */}
              </Col>
            }
          </Row>
          <Row>
            <Col span={24}>
              <div className={styled.profile_btns}>

                {!isDesktop && <Switch disabled={isEditMode ? false : true} onChange={(e) => e === true ? setGenre('male') : setGenre('female')} style={{ width: "100%", scale: 2, background: "#00308F", marginTop: 10, marginBottom: 10 }} checkedChildren="Male" unCheckedChildren="Female" defaultChecked={gender === 'female' ? false : true} defaultValue={gender === 'female' ? false : true} />}
                <div style={{ width: '100$' }}>

                </div>
                <div style={{ width: '100$' }}>

                </div>
              </div>
              </Col>
          </Row>
          <Row>
            <Col span={24} className={styled.profile_btns}>
              <Button style={{ width: "100%" }} onClick={() => navigate('/profile/bonus')} className={active === 0 ? styled.bonus_btn_active : styled.bonus_btn}>
                {
                  active === 0 ? <WalletActive className={styled.bonus_icon} /> : <Wallet className={styled.bonus_icon} />
                }
                <span>Bonus</span></Button>
              {!isDesktop ?
                <Button onClick={() => navigate('/profile/help')} className={active === 1 ? styled.help_btn_active : styled.help_btn}>
                  {active === 1 ? <ContactActive className={styled.bonus_icon} /> : <Contact className={styled.bonus_icon} />}
                  <span>Help</span>
                </Button>
                : null}
              {
                !isDesktop ? <Button
                  onClick={() => {
                    if (isEditMode) {
                      handleEdit();
                      setEditMode(false);
                    } else {
                      setEditMode(true);
                    }
                  }}
                  className={isEditMode ? styled.edit_btn_active : styled.edit_btn}>{isEditMode ? "Save" : "Edit"}</Button> : null
              }

            </Col>
          </Row>
          <ProfileMenu />
        </Col>
        <Col xs={24} lg={24} xl={{ span: 15 }} xxl={16}>
          <div className={styled.edit_desktop}>

            <span className={styled.nickname}>{nickname}</span>

            <Input onChange={(e) => { setNickname(e.target.value); setNickTouched(true) }} disabled={isEditMode ? false : true} placeholder={nickname} defaultValue={nickname} className={styled.input_edit} />
            <Input onChange={(e) => { setEmail(e.target.value); setEmailTouched(true) }} disabled={isEditMode ? false : true} placeholder={email} defaultValue={email} className={styled.input_edit} />
            <Switch disabled={isEditMode ? false : true} onChange={(e) => e === true ? setGenre('male') : setGenre('female')} style={{ width: 100, scale: 2, background: "#00308F" }} checkedChildren="Male" unCheckedChildren="Female" defaultChecked={gender === 'female' ? false : true} defaultValue={gender === 'female' ? false : true} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: "100%" }}>

              <Button style={{ marginBottom: 54, marginLeft: 0, width: 120 }}
                disabled={editDisabled}
                onClick={() => {
                  if (isEditMode) {
                    handleEdit();
                    setEditMode(false);
                  } else {
                    setEditMode(true);
                  }
                }}
                className={isEditMode ? styled.edit_btn_active : styled.edit_btn}>{isEditMode ? "Save" : "Edit"}</Button>
            </div>
          </div>
          <div className={styled.child}>
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
};


export { ProfileLayout };

const defaultType = "delete";

function useConfirm() {

  const confirmTypse: any = {
    delete: {
      title: "Want to delete an image?",
      okText: "Delete",
      content: ""
    },
    action: {},
  };


  const confirm = (onSuccess: () => void, content?: any, type?: string) => (
    Modal.confirm({
      title: confirmTypse[type ?? defaultType]["title"],
      // icon: <ExclamationCircleOutlined />,
      content: content ?? confirmTypse[type ?? defaultType]["content"],
      okText: confirmTypse[type ?? defaultType]["okText"],
      cancelText: "Close",
      onOk() {
        onSuccess?.();
        return;
      }
    })
  );

  return confirm;
}