import { toast } from 'react-toastify';

import { Button, Form, Input } from 'antd';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactAPI } from '../../api/api';
import styled from './Contacts.module.scss';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import MusicHeader from '../../components/MusicHeader';



const { TextArea } = Input;

interface IValue {
  nickname: string;
  email: string;
  message: string;
}


const Contacts = () => {
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState<boolean>(false)

  function sendMessage(value: IValue) {
    setDisabled(true)
    contactAPI
      .sendMessage(value.nickname, value.email, value.message)
      .then((res) => {
        toast.success('Success');
        form.resetFields();
        setDisabled(false)
      })
      .catch((error) => {
        toast.error("Something wrong");
        setDisabled(false)
      });
  }

  const onFinish = (values: any) => {
    sendMessage(values)
  };

  const isMobile = useMediaQuery({
    query: '(max-width: 430px)',
  });
  // const additionalStyles = isMobile ? { height: "45px", width: "255px" } : {};

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  return (
    <>
    {!isDesktop &&  <MusicHeader title='Contact us' primaryColor='#8894FF' color='#fff'/>}
      <div style={{ padding: isMobile ? "0 20px" : "0 20px", marginTop: isMobile ?  50: 18 }}>
        <p className={styled.description}>Please write to us your questions and suggestions</p>
        {/* <NotificationT /> */}
        <div className={styled.container}>
          <div className={styled.boxContainer}>
            <div style={{width: "100%"}}>

              <div className={styled.inputs}>

                <Form
                  form={form}
                  initialValues={{ layout: 'horizontal' }}
                  layout='horizontal'
                  className={styled.form}

                  onFinish={onFinish}
                >
                  {/* <div className={styled.short}> */}


                  <Form.Item className={styled.large_input} style={{ fontSize: 16 }} name={'nickname'} required>
                    <Input
                     placeholder='Name*'
                      className={styled.input} />
                  </Form.Item>
                  <Form.Item className={styled.large_input} name={'email'} required>
                    <Input placeholder='Email*' className={styled.input} type='email' />
                  </Form.Item>
                  {/* </div> */}

                  <Form.Item className={styled.large_input} name={'message'} required labelCol={{ span: 24 }} >
                    <TextArea placeholder='Type Your message here*' className={styled.input} autoSize={{ minRows: 6, maxRows: 8 }} />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      disabled={disabled}
                      className={styled.send_btn} htmlType='submit' type="default">Send</Button>
                  </Form.Item>
                </Form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Contacts };
