import React from "react";
import styles from './BattleDivider.module.scss'
import thunder from '../../assets/musicbet/bolt.svg'
import { useLocation, useNavigate } from "react-router-dom";

interface DividerProps {
 isDayX?: boolean
 primaryColor?: string,
 secondaryColor?: string
 id?: any
 display_id?: any
}

  const  BattleDivider = (props: DividerProps) => {
    const location = useLocation()
    const navigate = useNavigate()
   const mockColor = `linear-gradient(90deg, ${props.primaryColor} 0%, ${props.secondaryColor} 100%)`;

 return(
  <div onClick={location.pathname !== "/tournament/" ? () => { } : props.id === undefined ? () => { } : () => navigate(`/battle/?id=${props.id}&display=${props.display_id}`)} className={styles.wrapper}>
  <div style={{background: mockColor}} className={styles.wrapper}>
   <img src={thunder} alt="bolt" className={styles.thunder}/>
   <h2 className={styles.title}>X DAY FINAL</h2>
  </div>
  </div>
 )
}

export default BattleDivider;
