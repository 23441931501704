import { Col, Row } from 'antd';
import { MantineProvider } from "@mantine/core";

import Vimeo from '@u-wave/react-vimeo';


import styled from './GameRules.module.scss';
import { useMediaQuery } from 'react-responsive';
import MusicHeader from '../../components/MusicHeader';

import mock from '../../assets/musicbet/Gameplay animation.jpg'

const GameRules = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1201px)',
  });

  return (
    <>

      {!isDesktop && <MusicHeader title='Gameplay' primaryColor='#00A91F' color='#fff' />}

      <div className={styled.wrapper}>
        <Row >
          <Col span={24}>
          </Col>
        </Row>
        <Row className={styled.rules_container}>
            <Col span={24} style={{ marginBottom: 32 }}>
            <Vimeo
            video={'https://vimeo.com/1022036527/afc67e4cda?share=copy'}
            className={styled.player}
            // width={isDesktop ? "100%" : 'max-content !important'}
            // height={isDesktop ? "100%" : 'max-content !important'}
            // style={{width: '100%', height: "100%"}}
            width={isDesktop ? '800px' : "fit-content"}
            // height={isDesktop ? '500px' : "min-content"}
            />
            </Col>
          <Col span={24}>
            <h2 className={styled.subHeader}>
              PARTICIPATION:
            </h2>
            <p className={styled.text}>
              Players must meet the age requirements based on their jurisdiction. Access to Music.bet is prohibited in regions where such activities violate local laws.
            </p>

            <h2 className={styled.subHeader}>
              IN-GAME CURRENCY - TOKENS:
            </h2>
            <p className={styled.text}>
              The in-game currency on Music.bet is represented by MBT (Music Bet Token). These tokens are exclusive to the Music.bet platform and cannot be exchanged for real fiat money. </p>

            <h2 className={styled.subHeader}>
              TOKEN ACQUISITION:
            </h2>
            <p className={styled.text}>
              Tokens can be acquired at the rate of 1000 MBT = 1 USDT (Tether).
            </p>

            <h2 className={styled.subHeader}>
              MINIMUM BET:
            </h2>
            <p className={styled.text}>The minimum bet in Music.bet games is 1 MBT.
            </p>

            <h2 className={styled.subHeader}>
              GAMEPLAY:            </h2>
            <p className={styled.text}>
              The tournament follows an elimination play-off system with 8 music videos. The winner of each pair advances to the next round, while the loser is eliminated from the tournament. Players who predict and bet on the winner of a specific battle become the winners of that battle and receive MBT prizes. Players can place bets on two competing music videos within 48 hours of the battle starting. The video with the most player votes wins once the 48-hour timer expires. </p>
            <h2 className={styled.subHeader}>
              PRIZE FUND DISTRIBUTION:
            </h2>
            <p className={styled.text}>
              The prize pool is formed from the MBT tokens of losing players.  <br />
              Prize fund distribution: <br />
              - 70% of the prize pool goes to the winning players who bet on the winning video, with MBT distributed proportionally based on their bets. <br />
              - 15% of the prize pool is given to the owner of the winning music video from the Music.bet marketplace. <br />
              - 5% of the prize pool is given to the owner of the losing music video from the Music.bet marketplace. <br />
              - 10% of the prize pool goes to Music.bet as a platform fee.
            </p>
            <h2 className={styled.subHeader}>
              WITHDRAWING MBT FROM YOUR ACCOUNT:            </h2>
            <p className={styled.text}>
              You can withdraw MBT from your balance by providing the address of your external wallet in your account and specifying the amount of MBT available for withdrawal.
            </p>

            <h2 className={styled.subHeader}>
              PLAYER STRATEGY AND SUCCESS:            </h2>
            <p className={styled.text}>
              It’s important to remember that Music.bet is designed as an entertainment game where the key factors for success are the player's strategy, skills, and knowledge of the music industry. To improve your chances of winning, study and analyze the popularity of the musicians participating in battles, their music, and the strategies and behaviors of other players!
            </p>

            <h2 className={styled.subHeader}>
              COMPLIANCE:
            </h2>
            <p className={styled.text}>
              Players must comply with local laws, platform rules, and ethical standards.</p>

            <h2 className={styled.subHeader}>
            DISPUTE RESOLUTION:
            </h2>
            <p className={styled.text}>
            Music.bet reserves the right to resolve any disputes and make final decisions on matters related to the game. By participating, players agree to abide by these rules. Play responsibly!</p>
            {/* <h2 className={styled.subHeader}>
            IMPORTANT!!!
            </h2>
            <p className={styled.text}>
            The winning video is being decided by majority of the votes - not the amount of the bets!<br />
            The funds (the bets) of the players who bet on lost video is going to the prize pool which is being distributed between winning players and video owners.
            </p>

            <h2 className={styled.subHeader}>BATTLE EXAMPLE:</h2>
            <p className={styled.text}>Video #001 gained 2000 tokens in one week from 100 players who bid. At the same time, video #002 gained 1000 tokens in the same period of time, collecting votes from 200 players. </p>
            <p className={styled.text}>Respectively, video #002 from the example wins the battle as more people have voted for it.</p>
            <p className={styled.text}>The prize fund consists of the bets of losing players.</p>
            <p className={styled.text}>The owner models of the #002 video receives 10% of the prize fund. <br />The net income of the owner of video #002 in this battle is 10% out of 2000 tokens = 200 tokens.</p>
            <p className={styled.textBronze}>The owner models of the #001 video which has lost the battle receives 5% out of the prize fund. In our example video #001 owner's incentive prize will be 100 tokens </p>
            <p className={styled.textBronze}>Another 70% of the prize fund is distributed among the players who voted for the winner of the battle - Video #002, in exact proportion to their bets! </p>
            <p className={styled.textBronze}>1400 tokens distributed among 200 players (the player who bet more receives more money!)</p>
            <p className={styled.textBronze}>1 Token = 1 EURO on <a href="playporn.bet" target='_blank'>PlayPorn.bet</a></p>
          */}

            {/* <h2 className={styled.subHeader}>Lucky Spin</h2>
          <p className={styled.text}>On PlayPorn.bet, players can instantly multiply their tokens by playing the Lucky Spin.</p>
          <p className={styled.text}>Select one or more models participating in a particular spin, select the amount of the bet and make a spin. If your bet wins it will be multiplied by 4!</p>

          <h2 className={styled.subHeader}>SUPERWET</h2>
          <p className={styled.text}>Players who have earned 3000 tokens in games on PlayPorn.bet have a unique opportunity to order an Exclusive Custom Video from Superwet models based on their own scenario*.</p>
          <p className={styled.text}>* After selecting a model from the list and paying for the service, the PlayPorn.bet manager will contact the player to discuss the script and other details for shooting the video.</p>
          <p className={styled.text}>The price includes the cost of video production in the geographical location of the model at the time of order and the model’s fee. If, according to the player’s scenario, the model must film in another geographical location, the player will have to additionally pay travel expenses.</p>
          <p className={styled.text}>A unique link to the finished exclusive video will be sent to the player within 14 days after payment.</p>
        <p className={styled.text}>Important!!! The Custom Video script will necessarily be consistent with the model and will have to meet safety requirements in its production for performers, as well as be within the limits of “reasonable” without infringing on anyone’s rights and feelings.</p> */}
          </Col>
        </Row>
      </div>
    </>
  );
};

export { GameRules };
