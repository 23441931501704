import { Button, Col, Form, Input, Radio, RadioChangeEvent, Row, Space } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';

import styled from './Report.module.scss';
import { contactAPI } from '../../api/api';
import { toast } from 'react-toastify';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import MusicHeader from '../../components/MusicHeader';
import { useMediaQuery } from 'react-responsive';

const Report = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  return (
    <div className={styled.wrapper}>
      {
        !isDesktop && <MusicHeader title='Cookie policy' color='#fff' primaryColor='#0E664B' />
      }


      <div className={styled.wrapper_text}>
        <p className={styled.text} style={{ margin: "10px 0" }}>
          When you navigate and interact with our Websites, we use automatic data collection technologies to collect information about website activity.
        </p>
        <p className={styled.text}>We use cookies, which are small text files stored in your web browser or downloaded to your device when you visit a website</p>
        <p className={styled.text}>We currently use the following types of cookies, set by our Websites' domains or other domains we own or control:  </p>

        <p className={styled.text}>- Strictly Necessary Cookies: These cookies are necessary for the operation of our Websites. For example, they allow users to log in and check whether they have access to specific services or content.</p>
        <p className={styled.text}>- Functionality Cookies: These cookies help personalize and enhance your online experience on our Websites. They allow us to recognize you when you return to our Websites and remember, for example, your language preferences.</p>
        <p className={styled.text}>- Analytics Cookies: These cookies allow us to recognize and count users and see how users interact with and explore our Websites. These cookies help improve our Websites by ensuring users easily find what they are looking for. We also use third-party session recording technologies to better understand user experiences, with data being pseudonymized.</p>
        <p className={styled.text}>- Targeting and Advertising Cookies: These cookies record user visits to our Websites, the pages viewed, and the links followed to make our Websites more relevant to users' interests and serve ads that may interest them. These cookies are used for both unregistered and registered users.</p>
        <p className={styled.text}>Analytics, Functionality, and Targeting/Advertising cookies can be turned off anytime by accessing the "Manage cookies" option at the footer of our website. However, declining these cookies may disable some website functionalities and disrupt access to certain parts of our Website.  </p>
        <p className={styled.text}>Cookies may be either session or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires or you delete it. Expiration dates are set within the cookies; some may expire after a few minutes, others may last for years.  </p>
        <p className={styled.text}>Do Not Track: Our systems do not recognize browser "Do Not Track" signals.  </p>
        <p className={styled.text}>Use of Google Analytics: We use Google as a service provider to collect and analyze information on how users use our Websites, including through first-party cookies set by our domains and third-party cookies set by Google. Since we enabled IP anonymization for Google Analytics, Google anonymizes the last octet of an IP address and does not store your full IP address. Google uses this information only to provide Google Analytics services to us and will not use it for other purposes. Information collected by Google Analytics may be transferred to and stored by Google on servers in the United States under standard contractual clauses approved by the EU. You can learn more about how Google uses data here, and you can opt-out of Google Analytics by visiting the Google Analytics opt-out page or by accepting only necessary cookies.</p>

        <p className={styled.text}>
          Analytics, Functionality and Advertising / Targeting cookies can be switched off at any time by accessing the ‘Manage cookies’ option that is located at the footer of our website. However, if you decline to accept these cookies, some functionality on our Websites may be disabled and can interrupt your access to certain parts of our Website.<br />
          Cookies can be either session cookies or persistent cookies. A session cookie expires automatically when you close your browser. A persistent cookie will remain until it expires, or you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a few minutes while others may expire after multiple years.<br />
          Do Not Track: Our systems do not recognize browser “Do Not Track” signals.<br />
          Use of Google Analytics. We use Google as a service provider to collect and analyze information about how users use the Websites, including, by collecting website activity data through first-party cookies set by our domains, and third-party cookies set by Google. Because we activated IP anonymization for Google Analytics, Google will anonymize the last octet of a particular IP address and will not store your full IP address. Google will use the information only for the purpose of providing Google Analytics services to us and will not use this information for any other purposes. The information collected by Google Analytics may be transmitted to and stored by Google on servers in the United States pursuant to standard contractual clauses approved by the EU. You can learn more on how Google uses data <a href="https://policies.google.com/technologies/partner-sites">here</a>, and you can opt-out of Google Analytics by visiting the <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics opt-out page</a> or by accepting only necessary cookies.
        </p>

      </div>
    </div>
  );
};

export default Report;
