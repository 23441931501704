import { Container, Flex, LoadingOverlay, Slider, Transition, } from '@mantine/core';
import { useFullscreen } from '@mantine/hooks';
import { IconMaximize, IconMinimize } from '@tabler/icons-react';
import { isMobile } from 'react-device-detect'

import { useMemo, ReactElement, useRef, cloneElement, Children } from 'react';
import ReactPlayer from 'react-player';
import { EffectsOverlay } from './EffectsOverlay';

import { IconButton } from './IconButton';

import { usePlayer } from '../lib/usePlayer';
import { BooleanActionKind } from '../types';
import { ControlsBlock } from './ControlsBlock';

import { PipControl } from './PipControl';
import { PlaybackControl } from './PlaybackControl';
import { PlaybackRateSelect } from './PlaybackRateSelect';
import { QualitySelect } from './QualitySelect';
import { VolumeControl } from './VolumeControl';
import Play from '../../SongCard/SongPoster/components/Padlock/icons/Play';

export const Player = ({ id = "pideo", videos, preview, children }: { id?: string, videos: Record<number, string>, preview?: string, children?: ReactElement },) => {
    const {
        player,
        state,
        getToggleHandler,
        seekTo,
        onBuffer,
        onBufferEnd,
        setDuration,
        changeVolume,
        onProgress,
        changePlaybackRate,
        changeQuality,
        keyboardPressHandler,
        onDisablePIP,
        onReady,
    } = usePlayer(id)

    const { ref, toggle, fullscreen } = useFullscreen();
    const soup = useRef(null);

    const openActionsBar = getToggleHandler({
        type: BooleanActionKind.ACTIONS_BAR_VISIBLE,
        payload: true
    })
    const closeActionsBar = getToggleHandler({
        type: BooleanActionKind.ACTIONS_BAR_VISIBLE,
        payload: false
    })
    const togglePlay = getToggleHandler({ type: BooleanActionKind.PLAYING })
    const toggleMute = getToggleHandler({ type: BooleanActionKind.MUTED })
    const togglePip = getToggleHandler({ type: BooleanActionKind.PIP })

    const setVolumeVisible = (payload: boolean) => getToggleHandler({
        type: BooleanActionKind.VOLUME_VISIBLE,
        payload
    });
    const qualities = Object.keys(videos).map(Number);

    const {
        loading,
        playing,
        muted,
        volume,
        duration,
        pip,
        played,
        volumeVisible,
        actionsBarVisible,
        playbackRate,
        seek
    } = state;
    let { quality } = state;

    if (quality < 0) {
        quality = qualities.at(-1)
    }

    const videoSRC = Object.values(videos);
    const isSingle = videoSRC.length === 1;

    const actualVideo = useMemo(() => {
        if (isSingle) {
            return videoSRC[0];
        } else if (videos[quality]) {
            return videos[quality]
        } else {
            return videoSRC.at(-1);
        }
    }, [isSingle, quality, videoSRC, videos]);

    return (
        <Container
            fluid
            ref={ref}
            onMouseEnter={openActionsBar}
            onMouseLeave={closeActionsBar}
            pos={'relative'}
            size={'xl'}
            p={0}
            display={"flex"}
            style={
                {
                    alignItems: "center",
                    justifyContent: "center",
                }
            }
        >
            <LoadingOverlay
                visible={loading} zIndex={1000}
                loaderProps={{ color: 'white' }}
                overlayProps={{ radius: 'sm', blur: 1, backgroundOpacity: 0.3 }}
            />
            <EffectsOverlay
                volume={volume}
                playing={playing}
                seek={seek}
            />
            <div
                tabIndex={0}
                style={{ outline: 'none', overflow: "hidden", width: "100%", background: "rgb(0, 0, 0)", justifyContent: "center", display: "flex" }}
                onClick={togglePlay}
                onKeyDown={keyboardPressHandler}
                ref={soup}
            >
                <ReactPlayer
                    ref={player}
                    onReady={onReady}
                    onDisablePIP={onDisablePIP}
                    onBuffer={onBuffer}
                    onBufferEnd={onBufferEnd}
                    onDuration={setDuration}
                    onProgress={onProgress}
                    playing={playing}
                    light={function () {
                        if (preview) {
                            const isVideoPreview = /(.mov|.mp4|.webm)$/.test(preview);

                            if (isVideoPreview) {
                                return (
                                    <video
                                        autoPlay
                                        src={preview}
                                        style={{ height: "100%", width: "100%" }}
                                    />
                                );
                            }

                            return (
                                <div style={{width: "100%", height:"100%", objectFit: 'cover', aspectRatio: 16/9, overflow:'hidden'}}>

                                    <img
                                        alt={""}
                                        src={preview}
                                        style={{ width: '100%', height: '100%', overflow:'hidden' }}
                                    />
                                </div>

                            )
                        }

                        return false;
                    }()}
                    // height={fullscreen ? "100%" : 472}
                    playIcon={
                        <div style={{ position: 'absolute', bottom: "50%", transform: "translateY(50%)", opacity: 0 }}>
                            <Play />
                        </div>
                    }
                    height={"100%"}
                    width={"100%"}
                    volume={volume}
                    muted={muted}
                    playbackRate={playbackRate}
                    pip={pip}
                    url={actualVideo}
                    style={{ minHeight: isMobile ? 'auto' : "auto", maxHeight: '85vh' }} //TODO: fix height
                />
            </div>
            {
                fullscreen && (
                    <div onClick={event => event.stopPropagation} style={{ position: 'absolute', background: "rgba(255, 255, 255, .5 )", right: "50%", bottom: "10%", transform: 'translateX(50%)', padding: 24, borderRadius: 16, zIndex: 999 }}>
                        {
                            Children.map(children, (child, index) => cloneElement(child, { getPopupContainer: () => soup.current }))
                        }
                    </div>
                )
            }
            <Transition mounted={actionsBarVisible}>
                {(styles) => (
                    <Container
                        tabIndex={1}
                        onKeyDown={keyboardPressHandler}
                        style={{
                            ...styles,
                            maxWidth: '100%',
                            outline: 'none',
                            background: 'linear-gradient(0deg, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%)',
                            bottom: fullscreen ? 0 : 6,
                            overflow: 'visible'

                        }}
                        w={'100%'}
                        m={0}
                        pos={'absolute'}
                        left={0}
                        px={24}
                        pt={32}
                        pb={16}
                        bottom={0}
                    >
                        <Slider
                            label={null}
                            my={12}
                            color="red"
                            size="xs"
                            value={played}
                            max={duration}
                            onChange={seekTo}
                        />
                        <Flex justify={'space-between'}>
                            <ControlsBlock>
                                <PlaybackControl
                                    playing={playing}
                                    togglePlay={togglePlay}
                                />
                                <VolumeControl
                                    visible={volumeVisible}
                                    setVolumeVisible={setVolumeVisible}
                                    volume={muted ? 0 : volume}
                                    changeVolume={changeVolume}
                                    toggleMute={toggleMute}
                                />
                            </ControlsBlock>
                            <ControlsBlock>
                                <PlaybackRateSelect
                                    playbackRate={playbackRate}
                                    changePlaybackRate={changePlaybackRate}
                                />
                                {
                                    !isSingle && (
                                        <QualitySelect
                                            quality={quality}
                                            changeQuality={changeQuality}
                                            qualities={qualities}
                                        />
                                    )
                                }
                                {/* <PipControl pip={pip} togglePip={togglePip} /> */}
                                <IconButton onClick={toggle}>
                                    {fullscreen ? <IconMinimize /> : <IconMaximize />}
                                </IconButton>
                            </ControlsBlock>
                        </Flex>
                    </Container>
                )}
            </Transition>
            <style>
                {/* TODO: Resolve this crutch!!! */}
                {
                    `
                        .mantine-Container-root video {
                            max-height: 85vh;
                        }
                    `
                }
            </style>
        </Container>
    )
}